import WasmController from "react-lib/frameworks/WasmController";
import DoctorConsultUnknowDoctor from "issara-sdk/apis/DoctorConsultUnknowDoctor_apps_DPO";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import AppointmentList from "issara-sdk/apis/AppointmentList_apps_APP";
import AppointmentFinish from "issara-sdk/apis/AppointmentFinish_apps_APP";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import DoctorConsultOrderList from "issara-sdk/apis/DoctorConsultOrderList_apps_DPO";
import DoctorConsultOrderDetail from "issara-sdk/apis/DoctorConsultOrderDetail_apps_DPO";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import V3SyncEncounterView from "issara-sdk/apis/V3SyncEncounterView_apps_PRX";
import EpisodeList from "issara-sdk/apis/EpisodeList_core";
import SecretEncounterList from "issara-sdk/apis/SecretEncounterList_core";
import DoctorOrderDetail from "issara-sdk/apis/DoctorOrderDetail_apps_DPO";
import OperatingOrderList from "issara-sdk/apis/OperatingOrderList_apps_ORM";

import { CreateApiRequests } from "../../common/CardPatientListInterface";

// Form
import FormVisitSlip from "../FormVisitSlip";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { convertISOtoBEIfISO, formatDate } from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";

export type State = {
  selectedPatient?: any;
  masterOptions?: any;
  selectedEncounter?: any;
  buttonLoadCheck?: any;
  CreateUpdateEncounterSequence?: {
    sequenceIndex: string | null;
    encounterList: any[];
    division: any;
    doctor: any;
    patientCase: any;
    remark: any;
    note: any;
    underlying_disease: any;
    selectedEncounter: any;
    pregnancy_status?: any;
    pregnancy_period?: any;
    pregnancyPeriodOptions?: any[];
    pregnancyPeriodID?: any;
    episodeList?: any[];
    pregnancyLog?: any[];
    student?: number | null;
    student_degree?: string | null; // BACHELOR/POSTGRADUATE
    selectedEncounterItems?: any[];
  } | null;
  appointmentEncounterList?: any[];
  selectedAppointmentItems?: any[];
  successMessage?: any;
  errorMessage?: any;
  SetPatientSequence?: any;
  patientListMyBplus?: any[];
  selectedDivision?: any;
};

export const StateInitial: State = {
  CreateUpdateEncounterSequence: null,
  appointmentEncounterList: [],
  patientListMyBplus: [],
  selectedAppointmentItems: [],
  selectedDivision: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  device?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetActiveEncounterList: Handler = async (controller, params) => {
  const state = controller.getState();
  const started = formatDate(new Date());
  if (!state.CreateUpdateEncounterSequence) return;
  if (!Number.isInteger(state.selectedPatient?.id)) return;

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["divisionNameCode", {}],
        ["doctor", {}],
        ["patientCase", {}],
        ["episodeType", {}],
      ],
    },
  });

  const activeEncounterApis = {
    appList: {
      api: AppointmentList.list,
      payload: {
        apiToken: controller.apiToken,
        params: {
          exclude_canceled: true,
          // to_real_doctor_order: true, /// Issue 65180 เพื่อให้เห็น ยังไม่เป็น too real
          exclude_finished: true,
          patient: state.selectedPatient?.id,
        },
        extra: {
          device: controller.data?.device || null,
          division: controller.data?.division || null,
        },
      },
    },
    enList: {
      api: EncounterList.list,
      payload: {
        apiToken: controller.apiToken,
        params: {
          patient: state.selectedPatient.id,
          unexpired_only: true,
          ...(!params.isNoneFilterDate && { started }),
        },
      },
    },
    episode: {
      api: EpisodeList.list,
      payload: {
        apiToken: controller.apiToken,
        params: { include_finished: true, patient: state.selectedPatient.id },
      },
    },
    pregnancyAssessment: {
      api: FormDataLatest.retrieve,
      payload: {
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedPatient?.encounter_id || state.selectedEncounter?.id,
          form_code: "CardPregnancyAssessment",
          form_version: "1.0",
        },
        extra: { division: controller.data.division },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: activeEncounterApis,
    key: "activeEncounterApis",
  });

  const responses = await Promise.all(promises);

  const [appList, enList, episode, pregnancyAssessment] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }

  const errors = [appList, enList]
    .map((res: any) => (typeof res[1] === "string" ? "" : res[1]))
    .filter(Boolean);

  if (!!errors.length) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errors },
      });
    }
    return console.log("fetch encounter error: ", errors);
  }

  const filterENToDate = enList[0]?.items.filter(
    (item: any) => convertISOtoBEIfISO(item.started) === started
  );

  let encounterList = params.isNoneFilterDate ? enList[0]?.items : filterENToDate || [];
  let selectedEncounter = encounterList?.[0];

  if (params.editEncounterId) {
    selectedEncounter = encounterList.find((item: any) => item.id === params.editEncounterId);
  }

  controller.setState({
    CreateUpdateEncounterSequence: {
      ...state.CreateUpdateEncounterSequence,
      pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status || 1,
      pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period || 0,
      pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      sequenceIndex: "CreateOrUpdate",
      encounterList: encounterList || [],
      pregnancyPeriodOptions: [
        { key: 1, value: 0, text: "ไม่ระบุ" },
        { key: 2, value: 1, text: "ไตรมาสที่ 1" },
        { key: 3, value: 2, text: "ไตรมาสที่ 2" },
        { key: 4, value: 3, text: "ไตรมาสที่ 3" },
      ],
      // isEditEncounter
      selectedEncounter: params.isEditEncounter && selectedEncounter,
      division: params.isEditEncounter && selectedEncounter?.division,
      doctor: params.isEditEncounter && selectedEncounter?.doctor,
      patientCase: params.isEditEncounter && selectedEncounter?.patient_case,
      remark: params.isEditEncounter && selectedEncounter?.remark,
      note: params.isEditEncounter && selectedEncounter?.note,
      underlying_disease: params.isEditEncounter && selectedEncounter?.underlying_disease,
      student: params.isEditEncounter && selectedEncounter?.student,
      student_degree: params.isEditEncounter && selectedEncounter?.student_degree,
      episode: params.isEditEncounter && selectedEncounter?.episode,
      episodeList: episode[0]?.items || [],
    },
    appointmentEncounterList: appList[0]?.items || [],
  });
};

export const CreateOrUpdate: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.CreateUpdateEncounterSequence) return;
  if (params.action === "create" && Number.isInteger(state?.selectedPatient?.id)) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const encounter = await EncounterList.create({
      data: {
        type: "OPD",
        action: "OPD_NEW",
        patient: state.selectedPatient.id,
        division: state.CreateUpdateEncounterSequence.division,
        doctor: state.CreateUpdateEncounterSequence.doctor,
        patient_case: state.CreateUpdateEncounterSequence.patientCase,
        remark: state.CreateUpdateEncounterSequence.remark,
        note: state.CreateUpdateEncounterSequence.note,
        underlying_disease: state.CreateUpdateEncounterSequence.underlying_disease,
        // Additional fields (find out why we need them)
        student: state.CreateUpdateEncounterSequence.student || null,
        student_degree: state.CreateUpdateEncounterSequence.student_degree || null,
        episode: state.CreateUpdateEncounterSequence.episode || null,
        chief_complaints: "",
        location: "",
        zone: null,
      },
      apiToken: controller.apiToken,
    });
    console.log(encounter[1] ? encounter[1] : encounter[0]);
    if (encounter[1]) console.log("Error open a new encounter");
    if (encounter[0]) {
      let patient = {
        ...encounter?.[0],
        encounter_no: encounter?.[0]?.number,
        full_name: encounter?.[0]?.patient_name,
        gender_name: encounter?.[0]?.patient_gender_name,
        age: encounter?.[0]?.patient_age?.split("ปี")?.[0],
        birthdate: encounter?.[0]?.patient_birthdate,
      };

      const promiseArr = [
        // Save CardPregnancyAssessment
        FormDataList.create({
          data: {
            form_code: "CardPregnancyAssessment",
            form_name: "CardPregnancyAssessment",
            form_version: "1.0",
            encounter: encounter[0].id,
            action: "CONFIRM",
            data: {
              pregnancy_status: params.pregnancy?.pregnancy_status || 1,
              pregnancy_period: params.pregnancy?.pregnancy_period || 0,
            },
          },
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        }),
        // Refresh encounter list
        EncounterList.list({
          params: {
            unexpired_only: true,
            patient: state.selectedPatient.id,
          },
          apiToken: controller.apiToken,
        }),
      ];

      if (state.selectedPatient.is_secret === "S") {
        promiseArr.push(
          SecretEncounterList.create({
            apiToken: controller.apiToken,
            data: { items: [{ id: encounter[0].id, active: true }] },
          })
        );
      }

      const [pregnancyAssessment, encounterUpdate] = await Promise.all(promiseArr);

      console.log(encounterUpdate[1] ? encounterUpdate[1] : encounterUpdate[0]);

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: encounterUpdate[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: encounterUpdate[0]?.items || [],
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          student: null,
          episode: null,
          student_degree: null,
          // selectedEncounter: null,
          pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status || 1,
          pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period || 0,
          pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
          selectedEncounterItems: [
            ...(state.CreateUpdateEncounterSequence?.selectedEncounterItems || []),
            encounter[0].id,
          ],
        },
        SetPatientSequence: {
          ...state.SetPatientSequence,
          patient: {
            ...(state.SetPatientSequence?.patient || {}),
            patient,
          },
        },
      });
    }
    if (encounter[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: encounter[1],
        },
      });
    }
  } else if (params.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params.action === "select" && params.item) {
    const targetEncounter = state.CreateUpdateEncounterSequence?.encounterList.find(
      (encounter: any) => encounter.id === params.item?.id
    );

    const patient = {
      ...targetEncounter,
      encounter_no: targetEncounter.number,
      full_name: targetEncounter.patient_name,
      gender_name: targetEncounter.patient_gender_name,
      age: targetEncounter.patient_age?.split("ปี")?.[0],
      birthdate: targetEncounter.patient_birthdate,
    };

    const pregnancyAssessment = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: targetEncounter?.id,
        form_code: "CardPregnancyAssessment",
        form_version: "1.0",
      },
      extra: { division: controller.data.division },
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        selectedEncounter: { ...targetEncounter },
        division: params.item.division,
        doctor: params.item.doctor,
        patientCase: params.item.patient_case,
        remark: params.item.remark,
        note: params.item.note,
        underlying_disease: params.item.underlying_disease,
        student: params.item.student,
        student_degree: params.item.student_degree,
        episode: params.item.episode,
        pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
        pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      },
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: {
          ...(state.SetPatientSequence?.patient || {}),
          patient,
        },
      },
    });
  } else if (params.action === "update") {
    const { id, ...data } = state.CreateUpdateEncounterSequence.selectedEncounter;
    const doctorId = state.CreateUpdateEncounterSequence.doctor;

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    if (CONFIG.RAKSTHAI_ENCOUNTER_STATUS && data.status === "IN_EXAM") {
      await controller.handleEvent({
        message: "UpdateEncounterStatus",
        params: { id, current: data.status, target: "IN_QUEUE" },
      } as any);
    }

    const patientCoverage = await EncounterDetail.update({
      pk: id,
      data: {
        chief_complaints: data.chief_complaints,
        drug_allergy: data.drug_allergy,
        ended: data.ended,
        location: data.location,
        number: data.number,
        pk: data.pk,
        type: data.type,
        zone: data.zone,
        patient: state.selectedPatient.id,
        division: state.CreateUpdateEncounterSequence.division,
        doctor: doctorId,
        patient_case: state.CreateUpdateEncounterSequence.patientCase,
        remark: state.CreateUpdateEncounterSequence.remark,
        note: state.CreateUpdateEncounterSequence.note,
        student: state.CreateUpdateEncounterSequence.student || null,
        episode: state.CreateUpdateEncounterSequence.episode || null,
        student_degree: state.CreateUpdateEncounterSequence.student_degree || null,
        underlying_disease: state.CreateUpdateEncounterSequence.underlying_disease,
        action: "OPD_EDIT",
      },
      apiToken: controller.apiToken,
    });

    if (CONFIG.RAKSTHAI_ENCOUNTER_STATUS && data.status === "IN_EXAM") {
      await controller.handleEvent({
        message: "UpdateEncounterStatus",
        params: {
          id,
          doctorId,
          emrId: data.medical_record,
          current: data.status,
          target: "IN_EXAM",
        },
      } as any);
    }

    console.log("patientCoverage", patientCoverage[0], patientCoverage[1]);

    // Edit CardPregnancyAssessment
    const pregnancyAssessment = await FormDataDetail.update({
      pk: state.CreateUpdateEncounterSequence.pregnancyPeriodID,
      data: {
        form_code: "CardPregnancyAssessment",
        form_name: "CardPregnancyAssessment",
        form_version: "1.0",
        encounter: id,
        action: "CONFIRM",
        data: {
          pregnancy_status: params.pregnancy?.pregnancy_status,
          pregnancy_period: params.pregnancy?.pregnancy_period,
        },
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    // Consult checking and update for case "ไม่ระบุแพทย์"
    await DoctorConsultUnknowDoctor.put({
      apiToken: controller.apiToken,
      pk: state.CreateUpdateEncounterSequence.selectedEncounter?.id,
    });

    if (patientCoverage[0]) {
      // Refresh encounter list
      const encounterUpdate = await EncounterList.list({
        params: {
          unexpired_only: true,
          patient: state.selectedPatient.id,
        },
        apiToken: controller.apiToken,
      });

      params.onUpdated?.();

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: encounterUpdate?.[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: encounterUpdate?.[0]?.items || [],
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          student: null,
          episode: null,
          student_degree: null,
          pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
          pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
          // selectedEncounter: null,
        },
      });
    }
    if (patientCoverage[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: patientCoverage[1],
        },
      });
    }
  } else if (params.action === "clearData") {
    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        division: "",
        doctor: "",
        patientCase: "",
        remark: "",
        note: "",
        underlying_disease: "",
        student: null,
        episode: null,
        student_degree: null,
        selectedEncounter: null,
        pregnancy_status: 1,
        pregnancy_period: 0,
      },
    });
  } else if (params.action === "delete") {
    const { id, ...data } = state.CreateUpdateEncounterSequence.selectedEncounter;
    const patientCoverage = await EncounterDetail.delete({
      pk: id,
      apiToken: controller.apiToken,
      extra: {
        data: {
          username: params.username,
          password: params.password,
          note: params.note,
        },
        division: controller.data.division,
        device: controller.data.device,
      },
    });
    console.log("patientCoverage", patientCoverage[0], patientCoverage[1]);

    if (patientCoverage[1]) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: patientCoverage[1],
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: patientCoverage[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          student: null,
          episode: null,
          student_degree: null,
          selectedEncounter: null,
        },
      });

      // Refresh encounter list
      const encounterUpdate = await EncounterList.list({
        params: {
          unexpired_only: true,
          patient: state.selectedPatient.id,
        },
        apiToken: controller.apiToken,
      });
      console.log(encounterUpdate[1] ? encounterUpdate[1] : encounterUpdate[0]);
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: encounterUpdate[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: encounterUpdate[0]?.items || [],
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          student: null,
          episode: null,
          student_degree: null,
          selectedEncounter: null,
        },
      });
    }
  } else if (params?.action === "getLogPregnancy") {
    const pregnancyLog = await FormDataActionLogList.list({
      pk: state.CreateUpdateEncounterSequence.pregnancyPeriodID,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        pregnancyLog: pregnancyLog[0]?.items,
      },
    });
  }

  // APPOINTMENT SEGMENT
  else if (params?.action === "open_encounter_appointment") {
    const btnKey = `${params.card}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    const patientData = controller.data.masterData?.patientCase || [];
    const telemedId = patientData.find((data: any) => data.code.toLowerCase() === "telemed")?.id;

    let items = [...(state.appointmentEncounterList || [])]
      .filter((item: any) => (state.selectedAppointmentItems || [])?.includes(item?.id))
      .map((item: any) => ({
        ...item,
        checked: true,
        patient_case: item.is_telemed ? telemedId : undefined,
      }));
    // do something

    // * find patientAppointmentViewID
    const promiseArr = items.map((item) =>
      PatientAppointmentView.list({
        params: {
          division_id: item.division,
          start_date: item.date,
          end_date: item.date,
          patient_id: item.patient,
        },
        apiToken: controller.apiToken,
      }).then((res: any) => res[0]?.items || [])
    );

    const patientAppointmentRes = await Promise.all(promiseArr);

    const patientApps = patientAppointmentRes.flat();

    const patientAppIds = items
      .map((item) => patientApps.find((app) => app.content_id === item.id)?.id)
      .filter(Boolean);

    const patientAppPromiseArr = patientAppIds.map((id) =>
      PatientAppointmentUpdate.patch({
        pk: id,
        apiToken: controller.apiToken,
      })
    );

    // * update updated date
    await Promise.all(patientAppPromiseArr);

    const openAppointment = await AppointmentFinish.post({
      apiToken: controller.apiToken,
      data: {
        items: items,
      },
      extra: {
        division: controller.data?.division || null,
        device: controller.data?.device || null,
      },
    });

    if (openAppointment?.[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: openAppointment?.[1],
          },
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
        });
      }
      return console.log("Open encounter by appointment error: ", openAppointment?.[1]);
    }

    controller.setState(
      {
        selectedAppointmentItems: [],
        successMessage: {
          ...state.successMessage,
          [params.card]: { appointment: true },
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
      },
      async () => {
        await GetActiveEncounterList(controller, { card: params.card });
      }
    );
  } else if (params?.action === "open_encounter_sync_appointment") {
    const btnKey = `${params.card}_${params.action}`;
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    const compare = (a: any, b: any) => {
      const date_A = Number(a.replaceAll(/[^0-9]+/g, ""));
      const date_B = Number(b.replaceAll(/[^0-9]+/g, ""));
      return date_A - date_B;
    };
    let appointmentList = (state.patientListMyBplus || [])
      ?.filter((item: any) => item.hn === state.selectedPatient?.hn)
      .sort(compare);

    const [syncRes, syncError] = await V3SyncEncounterView.post({
      apiToken: controller.apiToken,
      data: {
        hospital: state.selectedDivision?.organization?.code || "",
        patient: state.selectedPatient?.id || "",
        from_date: appointmentList[0]?.date || "",
      },
    });

    if (syncError) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: syncError,
          },
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
        });
      }
      return console.log("sync encounter by appointment error: ", syncError);
    }

    controller.setState(
      {
        successMessage: {
          ...state.successMessage,
          [params.card]: { appointment: true },
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
      },
      async () => {
        await GetActiveEncounterList(controller, { card: params.card });
      }
    );
  } else if (params?.action === "checked_encounter_appointment") {
    let items = [...(state.selectedAppointmentItems || [])];
    if (items.includes(params?.appointmentId)) {
      items = items.filter((i: any) => i !== params?.appointmentId);
    } else {
      items.push(params?.appointmentId);
    }
    controller.setState({
      selectedAppointmentItems: items,
    });
  } else if (params.action === "getPregnancyAssessment") {
    const pregnancyAssessment = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardPregnancyAssessment",
        form_version: "1.0",
      },
      extra: { division: controller.data.division },
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
        pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      },
    });
  } else if (params.action === "savePregnancyAssessment") {
    const pregnancyAssessment = await FormDataDetail.update({
      pk: state.CreateUpdateEncounterSequence.pregnancyPeriodID,
      data: {
        form_code: "CardPregnancyAssessment",
        form_name: "CardPregnancyAssessment",
        form_version: "1.0",
        encounter: state.selectedEncounter?.id,
        action: "CONFIRM",
        data: {
          pregnancy_status: state.CreateUpdateEncounterSequence.pregnancy_status,
          pregnancy_period: state.CreateUpdateEncounterSequence.pregnancy_period,
        },
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (pregnancyAssessment[0]) {
      CreateOrUpdate(controller, { action: "getPregnancyAssessment" });
    }
  } else if (params.action === "print_visit_slip_form") {
    const btnKey = `${params.card}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    const appointmentList = await getAppointments(controller, params) || [];

    const doctorOrderDetailItems = await getDoctorOrderDetailByIds(controller, { appointmentList });

    const patientCaseOptions = state.masterOptions?.patientCase || [];

    const encounterList = state.CreateUpdateEncounterSequence?.encounterList || [];
    const selectedEncounterItems =
      state.CreateUpdateEncounterSequence?.selectedEncounterItems || [];

    const items = selectedEncounterItems.map((id) => encounterList.find((acc) => id === acc.id));

    const operatingOrderByEncounterItems = await getOperatingOrderByEncounters(controller, {
      selectedEncounterItems,
      encounterList
    });

    const itemsWithDoctorOrders = items.map((item) => {
      const matchingEncounter = appointmentList.find((acc: any) => acc.ref_encounter === item.id);
      const matchingOperatingEncounter = operatingOrderByEncounterItems.find((acc: any) => acc.id === item.id);
      const matchingDoctorOrderDetail = doctorOrderDetailItems.find((acc: any) => acc.id === item.id);

      const combinedOrders = (matchingEncounter?.children || []).reduce((acc: any[], appOrder: any) => {
        const detailOrder = (matchingDoctorOrderDetail?.response || []).find((detail: any) => detail.id === appOrder.id);
        const combinedOrder = {
          ...appOrder,
          ...detailOrder,
        };
        acc.push(combinedOrder);

        return acc;
      }, []);

      return {
        ...item,
        doctor_orders_app: combinedOrders || [],
        operating_orders_app: matchingOperatingEncounter ? matchingOperatingEncounter?.response : {},
      };
    });

    const patientDetail = items[0];

    const data = {
      ...patientDetail,
      items: itemsWithDoctorOrders.map((item) => ({
        ...item,
        patient_case:
          patientCaseOptions.find((acc: any) => acc.value === item.patient_case)?.text || "",
      })),
    };

    const docDef: any = await FormVisitSlip(data);

    const pdfMake = (await getPdfMake(true)).createPdf(docDef);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        selectedEncounterItems: [],
      },
    });

    pdfMake.open();
  }
};

/**--------------------------------------------
 **                   APIs
 *---------------------------------------------**/
const getAppointments: Handler = async (controller, params): Promise<any[]> => {
  const state = controller.getState();

  const [resp, err] = await AppointmentList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient.id,
      exclude_unconfirmed: true,
      status: 5,
    },
  });

  if (resp) return resp?.items;

  return [];
};

const getOperatingOrderByEncounters: Handler = async (controller, params) => {
  if (!params.selectedEncounterItems || !params.encounterList) {
    return [];
  }

  const items = params.selectedEncounterItems.map((id: any) => params.encounterList.find((acc: any) => id === acc.id));

  const getOperatingOrders = async (encounterId: number) => {
    const [resp, err] = await OperatingOrderList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: encounterId,
      },
    });

    return resp?.items || [];
  };

  const resps = await Promise.all(
    items.map((item: any) => getOperatingOrders(item.id))
  );

  return items.map((item: any, index: number) => ({
    id: item.id,
    response: resps[index][0] ?? {},
  }));
};

const getDoctorOrderDetailByIds: Handler = async (controller, params) => {
  if (!params.appointmentList) return [];

  const items = params.appointmentList;

  const getDoctorOrderDetail = async (orderId: number) => {
    const [resp, err] = await DoctorOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: orderId,
    });

    return resp || null;
  };

  const resps = await Promise.all(
    items.map((item: any) => {
      const childrenIds = item.children.map((child: any) => child.id);
      return Promise.all(childrenIds.map((id: number) => getDoctorOrderDetail(id)));
    })
  );

  return items.map((item: any, index: number) => ({
    id: item.ref_encounter,
    response: resps[index] ?? [],
  }));
};